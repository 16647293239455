/* NFT Card Styles */
.HomeH-card {
  background: #ffffff; /* Default background color */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 10px auto; /* Corrected margin for auto centering */
  max-width: 300px;
  position: relative; /* Needed for absolute positioning of children */
}

.HomeH-card-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.HomeH-card-content {
  padding: 15px;
  text-align: center;
}

.HomeH-card-badge-container {
  display: flex;
  justify-content: space-between;
  padding: 10px; /* Add padding for better spacing */
}

.HomeH-card-badge {
  position: absolute;
  padding: 5px 10px;
  border-radius: 0; /* Sharp corners for minimalistic design */
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  text-transform: uppercase; /* Optional: to make text more stylized */
  background: linear-gradient(
    135deg,
    #7b4397,
    #dc2430
  ); /* Deep purple gradient */
}

.HomeH-card-creator-badge {
  top: 0;
  left: 0;
}

.HomeH-card-quantity-badge {
  top: 0;
  right: 0;
}
/* Other Text Styles */
.HomeH-card-creator {
  font-size: 0.9em;
  color: #333;
  margin-bottom: 10px;
}

.HomeH-card-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.HomeH-card-description {
  color: #666;
  font-size: 1em;
  margin-bottom: 15px;
}

.HomeH-card-quantity {
  font-size: 1em;
  color: #333;
  margin-bottom: 15px;
}

/* Adjusted styles for MATIC icon and price */

.HomeH-card-content {
  padding: 15px;
  text-align: center;
}

.HomeH-card-price {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: bold;
  color: #8247e5; /* Purple color used in Polygon */
  margin: 20px 0;
}
.HomeH-card-price-usd {
  font-size: 0.9em;
  font-style: italic;
  color: #666; /* Grey color for USD price */
  margin-left: 10px;
}

/* Add the keyframe animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

/* Apply the animation to .price-info */
.price-info {
  position: relative;
  cursor: pointer;
  animation: pulse 1.5s infinite alternate;
  color: #00cc66; /* Green color */
}

.info-text {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
}

.price-info:hover .info-text {
  display: block;
}

.matic-icon {
  width: 4px; /* Adjust width as needed */
  height: auto;
  margin-left: 5px; /* Space between the text and the icon */
}

.HomeH-card-buy-button {
  background-color: #008cba;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.HomeH-card-buy-button:hover {
  background-color: #005f6b;
}
.HomeH-search-bar {
  display: flex;
  justify-content: center; /* Center align the search bar */
  padding: 10px; /* Padding around the search bar container */
}

.HomeH-search-bar input[type="text"] {
  width: 100%; /* Full width to take up container space */
  max-width: 500px; /* Maximum width of the search bar */
  padding: 8px 15px; /* Padding inside the search bar */
  font-size: 16px; /* Comfortable font size */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 25px; /* Rounded corners for modern look */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for interaction effects */
}

.HomeH-search-bar input[type="text"]::placeholder {
  color: #aaa; /* Placeholder text color */
}

.HomeH-search-bar input[type="text"]:focus {
  outline: none; /* Remove default focus outline */
  border-color: #007bff; /* Highlight color when focused */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6); /* Glow effect on focus */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
  .HomeH-search-bar input[type="text"] {
    padding: 6px 10px; /* Slightly smaller padding on small screens */
    font-size: 14px; /* Smaller font size on small screens */
  }
}
