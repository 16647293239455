.explainer-container {
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.explainer-page {
  display: flex;
  width: 200vw; /* Double viewport width for split-screen */
  height: 100vh;
  align-items: center;
  justify-content: space-around;
  transition: transform 0.5s ease-in-out;
}

.explainer-image,
.explainer-text {
  width: 50vw; /* Half viewport width */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explainer-image {
  object-fit: cover;
}

.explainer-text {
  font-size: 1.5rem;
  color: #ffffff;
  background: linear-gradient(135deg, #673ab7, #3f51b5, #2196f3);
  padding: 5vh 20px; /* Adjusted padding */
  border-radius: 0px;
  box-shadow: 0 6px 12px rgba(33, 150, 243, 0.4);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  word-wrap: break-word; /* Break long words to prevent overflow */
}

.explainer-text-content {
  text-align: center;
  display: block;
  margin: 0 auto;
  max-width: 90%; /* Adjust max-width for better text fit */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis to text that overflows */
  /* Optionally adjust font size and line height */
  font-size: smaller; /* Smaller font size */
  line-height: 1.2; /* Adjusted line height for better spacing */
}

.start-creating-btn {
  /* Styling */
  padding: 15px 30px; /* Increased padding for a larger button */
  margin: 25px auto; /* Centered margin for vertical alignment */
  background: linear-gradient(
    to right,
    #7e57c2,
    #673ab7,
    #5e35b1
  ); /* Enhanced gradient for more depth */
  color: white;
  border: none;
  border-radius: 30px; /* Pill-shaped border-radius */
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smoother transition for hover effects */

  /* Positioning */
  display: block; /* Block display for better centering */
  text-align: center; /* Ensure text is centered */
  position: relative; /* Relative positioning */
}

/* Hover and Focus Effects */
.start-creating-btn:hover,
.start-creating-btn:focus {
  background: linear-gradient(
    to right,
    #9575cd,
    #7e57c2,
    #673ab7
  ); /* Slightly lighter gradient on hover/focus */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover/focus */
}

/* Animation when button becomes visible */
.start-creating-btn.animate {
  opacity: 1;
  visibility: visible;
  animation: fadeInUp 0.5s ease forwards; /* Updated animation for a smooth fade-in */
}

@keyframes techyGlow {
  0% {
    transform: translateX(50%) scale(0.9);
    box-shadow: 0 0 0px rgba(103, 58, 183, 0);
  }
  50% {
    transform: translateX(50%) scale(1.05);
    box-shadow: 0 0 15px rgba(103, 58, 183, 0.75);
  }
  100% {
    transform: translateX(50%) scale(1);
    box-shadow: 0 0 5px rgba(103, 58, 183, 0.5);
  }
}

/* Hover effect for the button */
.start-creating-btn:hover {
  background: linear-gradient(
    45deg,
    #7e57c2,
    #b39ddb
  ); /* Slightly lighter purple gradient for hover */
}
.screen-number {
  font-size: 3rem; /* Large font size for visibility */
  background: linear-gradient(
    45deg,
    #7b1fa2,
    #9575cd
  ); /* Gradient from deep to light purple */
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Make the text color transparent to show the gradient */
  font-weight: bold;
  margin-bottom: 20px; /* Spacing below the number */
  text-align: center; /* Center the text */
}
.screen-number {
  font-size: 3rem; /* Large font size for visibility */
  color: #fff; /* Primary color as white */
  text-shadow:
    0px 0px 10px #7b1fa2,
    0px 0px 20px #7b1fa2; /* Glowing effect with deep purple */
  font-weight: bold;
  margin-bottom: 20px; /* Spacing below the number */
  text-align: center; /* Center the text */
}
/* Styling for the title "The Marketplace" */
@media screen and (max-width: 768px) {
  /* Adjustments for explainer container and page */
  .explainer-container {
    height: auto; /* Adjust height for mobile */
  }

  .explainer-page {
    flex-direction: column; /* Stack children vertically on mobile */
    width: 100vw; /* Adjust width for mobile */
  }

  .explainer-image,
  .explainer-text {
    width: 100vw; /* Full viewport width for each section */
    height: auto; /* Adjust height for mobile */
    padding: 20px; /* Add padding for better spacing */
  }

  .explainer-text {
    font-size: 1.2rem; /* Smaller font size for mobile */
    padding: 10vh 20px; /* Adjusted padding for text */
    /* Additional styling adjustments as needed */
  }

  .explainer-text-content {
    font-size: 1rem; /* Adjust font size for smaller screens */
    max-width: 100%; /* Use full width for text content */
    /* Additional styling adjustments as needed */
  }

  .start-creating-btn {
    padding: 10px 20px; /* Smaller padding for button */
    font-size: 1rem; /* Adjust font size for button */
    margin: 15px auto; /* Adjust margin for better spacing */
    /* Additional styling adjustments as needed */
  }

  .screen-number {
    font-size: 2rem; /* Adjust font size for screen numbers */
    /* Additional styling adjustments as needed */
  }

  /* Add any additional mobile-specific styles here */
}
