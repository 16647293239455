/* src/styles/globals.css */

/* Use a web-safe, modern font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

html,
body {
  height: 100%;
  margin: 0;
}
.AppM-navbar {
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically align all content */
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.AppM-title {
  color: #333333;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0; /* Remove margin to align with other elements */
}

.AppM-links {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-left: 10px; /* Adjust to align horizontally with the title */
}

.AppM-link {
  color: #555555;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1.3rem; /* Adjusted font size for better proportionality */
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  padding-bottom: 5px; /* Adjust padding as needed */
  margin-top: 10px; /* Add margin to align with the title */
  line-height: 1.5; /* Adjust line height for vertical centering */
  vertical-align: middle; /* Helps with vertical alignment */
}

.AppM-link-active {
  color: transparent;
  background: linear-gradient(-45deg, #9d50bb, #6e48aa, #00d2ff, #1fa2ff);
  -webkit-background-clip: text;
  background-clip: text;
}

/* Modify the hover state color to match the active state */
.AppM-link:hover {
  color: #1a73e8; /* Adjust to match your theme */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

/* Underline effect on hover */
.AppM-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background: #1a73e8; /* Adjust to match your theme */
  transition: width 0.3s;
}

.AppM-link:hover::after {
  width: 100%;
}

@media (max-width: 600px) {
  .AppM-navbar {
    flex-direction: column;
    padding: 3px; /* Reduced padding */
  }

  .AppM-navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .AppM-welcome-title {
    font-size: 1.2rem; /* Smaller font size for title */
    margin-bottom: 10px; /* Adjusted margin */
  }

  .AppM-links {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center links */
  }

  .AppM-link {
    font-size: 0.9rem; /* Smaller font size for links */
    margin: 5px 0; /* Adjusted margin for links */
  }

  /* Adjustments for Connect Wallet button */
  .ConnectWallet {
    /* Smaller size, adjusted margins/paddings */
  }
}

.AppM-MM {
  content-visibility:;

  border: none; /* Remove border if any */
  background: transparent; /* Ensure the background is transparent */
}

/* Additional adjustments may be required depending on the specific context in your application */
.AppM-wallet-container {
  padding-right: 10%; /* Adjust the value as needed */
}

.AppM-wallet-display {
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  border: 1px solid #d4d9df;
  border-radius: 30px;
  padding: 12px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.AppM-MM-addr {
  display: flex;
  align-items: center;
  color: #0077b5;
  max-width: 150px;
  cursor: pointer;
  position: relative;
  font-family: "Courier New", monospace;
  margin-right: 15px;
}

.copy-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  opacity: 0.7;
  transition:
    opacity 0.3s ease,
    transform 0.2s ease;
}

.copy-icon:hover {
  opacity: 1;
  transform: scale(1.1);
}

.AppM-tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 10;
}

.AppM-MM-addr:hover .AppM-tooltip {
  display: block;
}

.AppM-wallet-info {
  display: flex;
  align-items: center;
}

.AppM-connected-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  margin-right: 10px;
}

.AppM-balance-info {
  display: flex;
  align-items: center;
}

.AppM-balance {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Additional styles for responsiveness or specific scenarios can be added here */

@media screen and (max-width: 768px) {
  /* Responsive design adjustments */
  .AppM-wallet-display {
    flex-direction: column;
    align-items: flex-start;
  }
}

.AppM-welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  text-align: center;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed; /* Optional: makes it fixed during scrolling */
  top: 0;
  left: 0;
}

.bg-image-1 {
  background-image: url("./../assets/bg1.png");
}

.bg-image-2 {
  background-image: url("./../assets/bg2.png");
}

.bg-image-3 {
  background-image: url("./../assets/bg3.png");
}

.AppM-welcome-logo {
  width: 100px; /* Adjust the size as needed */
}

:root {
  --primary-text-color: #ffffff;
  --secondary-text-color: #e0e0e0;
  --button-color: #008cba;
  --button-hover-color: #0077a6;
  --font-family: "Roboto", sans-serif;
}

/* Base styles */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.AppM-welcome-title {
  margin-bottom: 1rem;
  font-family: var(--font-family);
  font-weight: bold;
  background: linear-gradient(-45deg, #9d50bb, #6e48aa, #00d2ff, #1fa2ff);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 15s ease infinite;
}

.AppM-welcome-message,
.AppM-welcome-message2 {
  margin-bottom: 2rem;
  color: #ffffff; /* White color for better visibility */
  letter-spacing: 0.5px;
  font-family: var(--font-family);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Shadow to make the white text stand out */
}

.AppM-connect-button {
  padding: 12px 24px;
  color: white;
  background-color: var(--button-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-family: var(--font-family);
}

.AppM-connect-button:hover {
  background-color: var(--button-hover-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
}

/* Mobile and small devices (width up to 600px) */
@media (max-width: 600px) {
  .AppM-welcome-title {
    font-size: 6vw;
  }

  .AppM-welcome-message,
  .AppM-welcome-message2 {
    font-size: 3.5vw;
  }

  .AppM-connect-button {
    font-size: 3.5vw;
  }
}

/* Tablets and medium devices (width between 601px and 1024px) */
@media (min-width: 601px) and (max-width: 1024px) {
  .AppM-welcome-title {
    font-size: 5vw;
  }

  .AppM-welcome-message,
  .AppM-welcome-message2 {
    font-size: 2.5vw;
  }

  .AppM-connect-button {
    font-size: 2.5vw;
  }
}

/* Larger devices (width more than 1024px) */
@media (min-width: 1025px) {
  .AppM-welcome-title {
    font-size: 4vw;
  }

  .AppM-welcome-message,
  .AppM-welcome-message2 {
    font-size: 2vw;
  }

  .AppM-connect-button {
    font-size: 1.1rem;
  }
}
.AppM-footer {
  text-align: center;
  padding: 40px 20px;
  background-color: #20232a;
  color: #ffffff;
  font-size: 0.9rem;
  border-top: 3px solid #6e48aa;
}

.AppM-footer-text {
  margin: 0 auto;
  width: 80%;
  line-height: 1.6;
  opacity: 0.8;
}

.AppM-footer a {
  color: #9d50bb;
  transition: color 0.3s ease;
}

.AppM-footer a:hover {
  color: #1fa2ff;
}

@media (max-width: 768px) {
  .AppM-footer {
    padding: 20px 10px;
    text-align: center; /* Centers the footer content */
  }

  .AppM-footer-text {
    width: 95%;
    font-size: 14px; /* Adjusts the font size for better readability on smaller screens */
    line-height: 1.6; /* Improves line spacing for easier reading */
    margin: 0 auto; /* Centers the text block within the footer */
    overflow-wrap: break-word; /* Ensures long words or URLs break to avoid horizontal scrolling */
  }
}

.AppM-footer-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.AppM-footer-icon {
  width: 30px; /* Adjust the size of the icons */
  height: auto;
  transition: transform 0.3s ease;
}

.AppM-footer-icon:hover {
  transform: scale(1.1);
}

.AppM-content-container {
  min-height: 100vh;
  padding-bottom: 60px;
}
.AppM-welcome-page .tw-connect-button {
  background: linear-gradient(
    to right,
    #673ab7,
    #3f51b5
  ); /* Gradient from deep purple to deep blue */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding for size */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor to pointer */
}

.AppM-welcome-page .tw-connect-button:hover {
  background: linear-gradient(
    to right,
    #5e35b1,
    #3949ab
  ); /* Slightly darker gradient for hover effect */
}
.connect-button.disabled {
  pointer-events: none; /* Disable click events */
  opacity: 0.5; /* Visually indicate it's disabled */
}
/*T&C*/

.terms-checkbox input {
  margin-right: 10px;
}

.terms-checkbox label {
  color: #ffffff; /* White color for the entire label */
  cursor: default; /* Default cursor for the non-clickable part */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto; /* Enable scrolling for long content */
}
.terms-highlight {
  color: #007bff; /* Use a distinct color */
  text-decoration: underline; /* Underline to indicate it's clickable */
  cursor: pointer; /* Change cursor to indicate interactivity */
  font-weight: bold; /* Optionally make it bold for emphasis */
}
.terms-checkbox {
  margin: 20px 0;
  display: inline-block; /* Adjust as needed */
  background-color: #f2f2f2; /* Soft grey background */
  padding: 8px 15px;
  border-radius: 15px; /* Rounded corners for a softer look */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.terms-checkbox label {
  color: #000; /* White color for the label text */
  cursor: default;
}

.terms-highlight {
  color: #007bff; /* Color for clickable text */
  text-decoration: underline; /* Underline to suggest interactivity */
  cursor: pointer;
  font-weight: bold;
}
/* Modal Content */
.modal-content {
  /* existing styles */
  position: relative; /* For positioning the close button */
}

/* Close Button as 'X' in the Top Right Corner */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent; /* Invisible background */
  color: #333; /* Dark color for visibility */
  font-size: 24px; /* Larger font size for 'X' */
  border: none;
  cursor: pointer;
}

.close-button:hover {
  color: #000; /* Darker color on hover */
}

/* Accept Button Styling */
.accept-button {
  background-color: transparent; /* Invisible background */
  color: #4caf50; /* Green text color */
  border: 2px solid #4caf50; /* Green border */
  padding: 10px 15px;
  margin: 20px 0; /* Space around the button */
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.accept-button:hover {
  background-color: #e8f5e9; /* Very light green background on hover */
}
.navbar-toggle {
  display: none; /* Hide the toggle button on larger screens */
}

/* Mobile specific styles */
@media screen and (max-width: 768px) {
  .navbar-toggle {
    display: block; /* Show the toggle button on mobile */
    /* Additional styling for the toggle button (if needed) */
  }

  .AppM-links {
    display: none; /* Hide the links by default on mobile */
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center-align the links */
    padding: 0; /* Remove default padding */
    margin-top: 20px; /* Add some space above the links */
  }

  .AppM-navbar.expanded .AppM-links {
    display: flex; /* Display links as a flex container when expanded */
  }

  .AppM-links .AppM-link {
    display: block; /* Display each link as a block */
    margin-bottom: 10px; /* Add some space between each link */
    text-align: center; /* Center-align the text of each link */
    width: 100%; /* Full width for easier touch targets on mobile */
    padding: 8px; /* Padding for touch friendliness */
    /* Additional styling for link appearance (if needed) */
  }
}

/* Mobile-specific styles for navigation bar */
@media screen and (max-width: 768px) {
  .navbar-toggle {
    display: block;
    font-family: var(--font-family);
    font-weight: bold;
    background: linear-gradient(-45deg, #9d50bb, #6e48aa, #00d2ff, #1fa2ff);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradientAnimation 15s ease infinite;
    border: none; /* Remove any default border */
    cursor: pointer; /* To indicate it's clickable */
    padding: 8px 16px; /* Adjust padding as needed */
  }

  /* Style for the icon inside the button */
  .navbar-toggle-icon {
    font-size: 20px; /* Adjust size as needed */
  }
}

/* Keyframes for the background animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
