/* Overall Container */
.CreatorC-container {
  font-family: "Roboto", sans-serif;
  color: #333;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Search Bar */
input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 15px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Section Titles */
.CreatorC-section-title {
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #007bff;
}

/* Items Container */
.CreatorC-items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

/* Individual Item */
.CreatorC-item {
  width: calc(50% - 20px); /* Two items per row on larger screens */
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-bottom: 20px;
}

.CreatorC-item:hover {
  transform: translateY(-5px);
}

/* Image Styling */
.CreatorC-image {
  width: 100%;
  height: auto;
  display: block;
}

/* Item Details */
.CreatorC-item-details {
  padding: 15px;
  text-align: center;
}

/* Item Name Styling */
.CreatorC-item-name {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Item Description Styling */
.CreatorC-item-description {
  margin: 5px 0;
  font-size: 14px;
  font-style: italic;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  margin: auto;
}

.CreatorC-item-price {
  margin: 5px 0;
  font-weight: bold;
  color: #28a745;
}

/* Modal Overlay */
.Overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Modal Styling */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Styling */
.ReactModal__Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%; /* Adjusted max width for mobile */
  width: auto;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Rest of your modal content styling */
.Modal-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}
.Modal-text {
  color: #333;
}

.Modal-text h2 {
  font-size: 22px;
  color: #007bff;
  margin-bottom: 10px;
}

.Modal-text p {
  font-size: 16px;
  margin-bottom: 5px;
}

.Modal-price {
  font-weight: bold;
  color: #28a745;
}

.Modal-close-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.Modal-close-btn:hover {
  background-color: #0056b3;
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.tab-btn {
  padding: 15px 30px;
  border: 2px solid transparent;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  transition: all 0.3s ease;
  border-radius: 20px;
  margin: 0 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tab-btn:hover {
  background-color: #f8f8f8;
  border-color: #e0e0e0;
}

.tab-btn.active {
  background-color: #4a90e2;
  color: white;
  border-color: #4a90e2;
}

/* Add this CSS for the tooltip */
.tab-btn.disabled {
  position: relative;
  cursor: not-allowed;
  opacity: 0.6;
}

.tab-btn.disabled:hover {
  background-color: #ff0000;
  color: #ffffff;
}

.tab-btn.disabled::after {
  content: "Coming Soon";
  position: absolute;
  background-color: #333;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tab-btn.disabled:hover::after {
  opacity: 1;
}

.CreatorC-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.CreatorC-item-remove-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

.CreatorC-item-remove-btn:hover {
  background-color: darkred;
}

/* Custom Web3Button style */
.web3Button {
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  transition:
    background-color 0.3s,
    color 0.3s;
  font-size: 16px;
  cursor: pointer;
}

.web3Button:hover {
  background-color: red;
  color: white;
}

.TokenID-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4a90e2;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
/* Overall Container */
.CreatorC-container {
  font-family: "Roboto", sans-serif;
  color: #333;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Search Bar */
input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 15px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Section Titles */
.CreatorC-section-title {
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #007bff;
}

/* Items Container */
.CreatorC-items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

/* Individual Item */
.CreatorC-item {
  width: calc(50% - 20px); /* Two items per row on mobile */
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-bottom: 20px;
}

.CreatorC-item:hover {
  transform: translateY(-5px);
}

/* Image Styling */
.CreatorC-image {
  width: 100%;
  height: auto;
  display: block;
}

/* Item Details */
.CreatorC-item-details {
  padding: 15px;
  text-align: center;
}

/* Item Name Styling */
.CreatorC-item-name {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Item Description Styling */
.CreatorC-item-description {
  margin: 5px 0;
  font-size: 14px;
  font-style: italic;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  margin: auto;
}

.CreatorC-item-price {
  margin: 5px 0;
  font-weight: bold;
  color: #28a745;
}

/* Modal Overlay */
.Overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Modal Styling */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Styling */
.ReactModal__Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%; /* Adjusted max width for mobile */
  width: auto;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Rest of your modal content styling */
.Modal-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}
.Modal-text {
  color: #333;
}

.Modal-text h2 {
  font-size: 22px;
  color: #007bff;
  margin-bottom: 10px;
}

.Modal-text p {
  font-size: 16px;
  margin-bottom: 5px;
}

.Modal-price {
  font-weight: bold;
  color: #28a745;
}

.Modal-close-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.Modal-close-btn:hover {
  background-color: #0056b3;
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.tab-btn {
  padding: 15px 30px;
  border: 2px solid transparent;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  transition: all 0.3s ease;
  border-radius: 20px;
  margin: 0 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tab-btn:hover {
  background-color: #f8f8f8;
  border-color: #e0e0e0;
}

.tab-btn.active {
  background-color: #4a90e2;
  color: white;
  border-color: #4a90e2;
}

/* Add this CSS for the tooltip */
.tab-btn.disabled {
  position: relative;
  cursor: not-allowed;
  opacity: 0.6;
}

.tab-btn.disabled:hover {
  background-color: #ff0000;
  color: #ffffff;
}

.tab-btn.disabled::after {
  content: "Coming Soon";
  position: absolute;
  background-color: #333;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tab-btn.disabled:hover::after {
  opacity: 1;
}

.CreatorC-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.CreatorC-item-remove-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

.CreatorC-item-remove-btn:hover {
  background-color: darkred;
}

/* Custom Web3Button style */
.web3Button {
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  transition:
    background-color 0.3s,
    color 0.3s;
  font-size: 16px;
  cursor: pointer;
}

.web3Button:hover {
  background-color: red;
  color: white;
}

.TokenID-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4a90e2;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
/* Media query for mobile screens */
@media (max-width: 767px) {
  .CreatorC-item {
    width: 100%; /* Full width on mobile screens */
  }
}
@media (max-width: 768px) {
  .web3Button {
    /* Adjust styles for smaller screens */
    font-size: 14px; /* Decrease font size, for example */
    padding: 10px 20px; /* Adjust padding, for example */
  }
}
