.image-generator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Added padding for overall spacing */
}

.image-generator-input {
  width: 100%; /* Full width for smaller screens */
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  overflow: hidden; /* Hide the scrollbar */
  resize: none; /* Disable manual resizing */
  height: auto; /* Automatically adjust height */
  min-height: 50px; /* Minimum height */
}

@media (min-width: 600px) {
  .image-generator-input {
    width: 50%;
    padding: 15px;
    font-size: 18px;
  }
}

/* Adjust the input field for larger screens */
@media (min-width: 600px) {
  .image-generator-input {
    width: 50%; /* Restrict width on larger screens */
    padding: 15px; /* More padding */
    font-size: 18px; /* Larger font size */
  }
}

.image-generator-button {
  padding: 12px 20px;
  background-color: #008cba; /* Blue background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px; /* Match font size with input */
}

.image-generator-button:hover {
  background-color: #007b9e; /* Darker blue */
}

/* Style adjustments for larger screens */
@media (min-width: 600px) {
  .image-generator-button {
    padding: 15px 25px;
    font-size: 18px; /* Slightly larger font for desktop */
  }
}

.generated-image {
  margin-top: 20px;
  max-width: 100%;
  border-radius: 5px;
}

/* Additional styles for download button if needed */
.image-download-button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #4caf50; /* Green background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.image-download-button:hover {
  background-color: #45a049; /* Darker green */
}

/* Style for the copy button */
.copy-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.copy-button img {
  height: 20px; /* Adjust the size as needed */
  width: auto;
}
