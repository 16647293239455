/* MintingLoader Styles */
.minting-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.minting-loader-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  opacity: 1; /* Initial opacity before GSAP animation */
  width: auto; /* Adjust width as needed */
  max-width: 90%; /* Ensure it doesn't overflow on small screens */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}

/* Loader Styles */
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-text {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1rem; /* Adjust font size for readability */
  color: #333; /* Dark color for text */
}

/* Specific Styles for the React Loader Spinner */
.Grid {
  margin: 0 auto; /* Center the spinner */
}
/* Styles for the loader message */
.loader-message {
  margin-top: 20px;
  font-size: 1rem;
  color: #333;
  /* Add more styling as required */
}
