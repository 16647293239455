/* Include a modern font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

/* Global Styles */
:root {
  --primary-color: #007bff;
  --secondary-color: #6610f2;
  --background-color: #1a1a1a; /* Dark background for a web3 feel */
  --text-color: #f2f2f2; /* Light text for contrast */
  --error-color: #d9534f;
  --success-color: #5cb85c;
  --input-bg-color: #2b2b2b; /* Slightly lighter background for inputs */
  --tooltip-bg-color: #007bff;
  --border-radius: 10px;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  --font-size: 16px;
}

body {
  font-family: "Roboto", sans-serif;
}

/* Common Styles for Success and Error Messages */

.create-item-error {
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.create-item-error {
  background-color: rgba(217, 83, 79, 0.2); /* Light red background */
}

.create-item-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-item-wrapper {
  width: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

/* Step Indicator Container */
.step-indicator {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: transparent;
  border-radius: var(--border-radius);
  margin-bottom: 30px;
  position: relative; /* Add relative positioning to the container */
}

/* Individual Step Styles */
.step {
  font-size: 1em;
  color: #aaa;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px;
  border: 2px solid transparent;
  background-color: transparent;
  position: relative;
}

/* Add this style for dotted lines */
.step::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(50% - 16px); /* Adjust the length of the lines */
  height: 2px;
  background-color: var(--primary-color);
  border-radius: 1px; /* Create a subtle effect for the dashed lines */
  background-image: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 4px,
    /* Adjust the spacing between dashes */ var(--primary-color) 4px,
    /* Adjust the spacing between dashes */ var(--primary-color) 8px
      /* Adjust the spacing between dashes */
  );
  z-index: -1; /* Place the lines behind the step content */
}

/* Style the first step's line */
.step:first-child::before {
  left: 0;
}

/* Style the last step's line */
.step:last-child::before {
  right: 0;
}

/* Style for active step */
.step.active {
  font-weight: bold;
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

/* Style for completed step */
.step.completed {
  color: var(--success-color);
  border-color: var(
    --success-color
  ); /* Change the border color for completed steps */
}

/* Style for the checkmark icon in completed step */
.step.completed::before {
  background-color: var(--success-color);
  content: "\2713"; /* Unicode character for a check mark */
  color: #fff; /* Text color for the checkmark */
  border-radius: 50%; /* Make the checkmark a circle */
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  line-height: 24px; /* Center the checkmark vertically */
  font-size: 1.2em; /* Adjust size as needed */
  position: absolute;
  top: -14px; /* Adjust vertical alignment */
  left: 50%;
  transform: translateX(-50%);
}

/* Update Home.css */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px; /* Width of the switch */
  height: 34px; /* Height of the switch */
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Off state color */
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px; /* Height of the slider */
  width: 26px; /* Width of the slider */
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4a90e2; /* On state color */
}

input:focus + .slider {
  box-shadow: 0 0 1px #4a90e2;
}

input:checked + .slider:before {
  transform: translateX(26px); /* Move slider to the right when checked */
}

.toggle-switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between toggle switch and label */
  margin-bottom: 20px;
}

.toggle-switch-label {
  color: #000; /* Adjust color as needed */
  font-size: 1rem;
}

/* Optional: styles for screen readers */
.toggle-switch input:disabled + .slider {
  background-color: #eaeaea;
}

.create-item-file-input,
.create-item-input,
.create-item-textarea {
  width: 90%;
  padding: 12px;
  margin-bottom: 30px;
  position: relative; /* Required for absolute positioning of child elements */

  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  font-size: var(--font-size);
  background-color: var(--input-bg-color);
  color: var(--text-color);
}

.create-item-input.input-invalid,
.create-item-textarea.input-invalid {
  border-color: var(--error-color);
}

/* Button Base Style */

/* Button Base Style */
.create-item-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  background: #f2f2f2; /* Soft gray for a minimalist look */
  color: #333; /* Dark text for contrast */
  border: 2px solid transparent; /* Ready for hover effect */
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--font-size);
  font-family: "Roboto", sans-serif;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease,
    transform 0.1s ease;
}

/* Button Hover State */
.create-item-button:hover {
  background: linear-gradient(
    45deg,
    var(--primary-color),
    /* Primary color from the variable */ var(--secondary-color)
      /* Secondary color from the variable */
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Button Active State */
.create-item-button:active {
  background-color: #eaeaea; /* Brief color pulse */
  transform: translateY(1px); /* Slight press effect */
}

.create-item-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.create-item-error {
  color: var(--error-color);
}

.create-item-help {
  text-align: center;
  margin-top: 20px;
}

.create-item-tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Makes the div circular */
  background-color: var(--primary-color); /* Background color of the circle */
  color: #fff; /* Color of the 'i' text */
  font-weight: bold;
  font-size: 14px; /* Adjust size as needed */
  cursor: help;
  text-align: center;
}

.create-item-tooltip .create-item-tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: var(--tooltip-bg-color);
  color: #fff;
  text-align: center;
  border-radius: var(--border-radius);
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 150%; /* Adjust position relative to the new icon */
  left: 50%;
  transform: translateX(-50%);
  box-shadow: var(--box-shadow);
  opacity: 0;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.create-item-tooltip:hover .create-item-tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateY(-10px);
}

/* Layout Adjustments for Success Message */

/* Styling for NFT Details in Success Message */
.create-item-success div {
  text-align: left;
  margin-top: 10px;
}

.create-item-success p {
  margin: 5px 0;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .create-item-wrapper {
    width: 95%; /* Adjust width for smaller screens */
  }

  .create-item-tooltip .create-item-tooltip-text {
    width: 150px; /* Adjust tooltip width for smaller screens */
  }
}
/* Styling for each step in the item creation process */
.create-item-step {
  background-color: #fff; /* Slight overlay for depth */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

/* Loading component styling */
.create-item-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* File input styling - keeping it minimal and modern */
.create-item-file-input {
  background-color: var(--input-bg-color);
  color: var(--text-color);
  border: 2px dashed #555; /* Dashed border for a distinct look */
  padding: 15px;
  text-align: center;
  cursor: pointer;
  transition:
    border-color 0.3s ease,
    background-color 0.3s ease;
}

.create-item-file-input:hover {
  border-color: var(--primary-color);
  background-color: rgba(0, 123, 255, 0.1);
}

/*NFT MINT DETAILS*/
/* Center the create-item-image */
.create-item-image {
  display: block;
  max-width: 80%; /* Responsive image sizing */
  height: auto;
  margin: 0 auto 20px; /* Centering the image */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Center the nft-details */
.nft-details {
  font-family: "Roboto", sans-serif; /* Modern, clean font */
  width: 50%;
  font-size: 1em; /* Clear font size */
  color: #eaeaea; /* Crisp, readable text color */
  background: #2c3e50; /* Deep, rich background color */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content vertically and horizontally */
  gap: 10px; /* Spacing between elements */
  margin: 0 auto; /* Center the nft-details horizontally */
  max-width: 80%; /* Limit the maximum width for responsiveness */
}

.nft-detail {
  margin: 5px 0; /* Uniform margin for all details */
  line-height: 1.4;
  max-width: 80%; /* Max width for better readability */
  word-wrap: break-word; /* Break long words for smaller screens */
}

.nft-detail.name {
  font-weight: bold;
  font-size: 18px; /* Matched with .CreatorC-item-name */
  color: #007bff; /* Eye-catching blue color */
  text-transform: uppercase; /* Uppercase letters */
  letter-spacing: 1px; /* Letter spacing */
  margin: 10px 0;
}

.nft-detail.description {
  font-size: 14px; /* Matched with .CreatorC-item-description */
  font-style: italic;
  color: #555; /* A softer color */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  margin: auto;
}

.nft-detail.price {
  font-weight: bold; /* Bold price */
  color: #28a745; /* Green for price */
  background-color: rgba(0, 184, 148, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  display: inline-block;
  text-align: center;
  margin: 5px 0;
}

.nft-detail.price:hover {
  background-color: rgba(0, 184, 148, 0.2);
  transform: scale(1.05);
}

/* Hover Effect for Interactive Feel */
.nft-details:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px);
  transition: all 0.3s ease;
}

/* Button styling - cohesive with the theme */

/*success message*/
.create-item-success {
  background-color: #1a202c; /* Dark background for a techy look */
  color: #f0f0f0; /* Light text for contrast */
  border: 1px solid #4a5568; /* Subtle border */
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  font-family: "Roboto Mono", monospace; /* Modern monospace font */
}

.success-message {
  font-size: 18px; /* Larger font size */
  font-weight: bold;
  align-items: center; /* Align items vertically */
  margin-bottom: 20px; /* Space below the message */
}
.success-message-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust as needed */
}

.nft-content {
  display: block; /* Using flexbox for layout */
  align-items: center; /* Align items vertically */
  gap: 20px; /* Space between image and details */
}

/* Center the nft-preview-image */
.nft-preview-image {
  width: 100%;
  max-width: 200px; /* Adjust based on your layout */
  border-radius: 8px;
  border: 2px solid #2d3748; /* Border to highlight the image */
  display: block;
  margin: 0 auto; /* Center the image horizontally */
}

/*blockchain data*/
/* Restyled Blockchain-details-button */
.Blockchain-details-button {
  background-color: #5a12ad; /* Green background for a standout look */
  color: white; /* White text for contrast */
  border: none; /* No border for a clean, modern look */
  padding: 8px 16px; /* Reduced padding for a less bulky appearance */
  border-radius: 5px; /* Rounded corners */
  font-size: 14px; /* Slightly smaller font size */
  cursor: pointer; /* Cursor changes to a pointer to indicate clickability */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  margin: 20px auto; /* Center the button horizontally and add margin */
  display: block; /* Make the button a block-level element for centering */
}

.Blockchain-details-button:hover {
  background-color: #45a049; /* Slightly darker green on hover for interactive feel */
}

.Blockchain-details-button:focus {
  outline: none; /* Removes the default focus outline to maintain the design */
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5); /* Adds a subtle glow when focused */
}

.blockchain-data {
  background-color: #f4f4f4; /* Light grey background */
  border: 1px solid #ddd; /* Slight border for definition */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Padding inside the container */
  margin-top: 20px; /* Space above the container */
  font-family: "Courier New", Courier, monospace; /* Monospace font for a techy look */
  font-size: 14px; /* Slightly smaller font size for compact display */
  color: #333; /* Dark grey text for readability */
  line-height: 1.6; /* Spacing between lines */
}

.blockchain-data p {
  margin: 5px 0; /* Spacing between each detail */
  padding: 5px 10px; /* Padding inside each detail line */
  background-color: #fff; /* White background for each detail line */
  border: 1px solid #e1e1e1; /* Border for each detail line */
  border-radius: 4px; /* Rounded corners for each detail line */
}

.blockchain-data p:nth-child(odd) {
  background-color: #e9e9e9; /* Slight grey background for alternating lines */
}
.transaction-modal {
  position: fixed; /* Fixes the position relative to the viewport */
  left: 0;
  top: 0;
  width: 100%; /* Full width of the viewport */
  height: 100%; /* Full height of the viewport */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  z-index: 1000; /* Ensures the modal is above other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for a floating effect */
  max-width: 500px; /* Maximum width of the modal */
  width: 90%; /* Width relative to the screen size */
  position: relative; /* For positioning the close button */
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1.5em;
  cursor: pointer;
  border: none;
  background: none;
}

/* Style for the PolyScan button */
.polyscan-button {
  background-color: #fff; /* Change to desired background color */
  border: 1px solid #ccc; /* Border color */
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.polyscan-button img {
  height: 20px; /* Adjust the size as needed */
  width: auto;
  margin-right: 5px;
}

/*step 2 input errors*/
.input-error-message {
  color: #ff4d4f; /* Vibrant red color for visibility */
  font-size: 14px;
  background-color: #fff; /* White background to stand out */
  border: 1px solid #ff4d4f; /* Border matching the text color */
  border-radius: 5px; /* Rounded corners */
  padding: 5px 10px;
  position: absolute; /* Absolute positioning to float above */
  bottom: -30px; /* Position below the input field */
  left: 50%; /* Center align below the input */
  transform: translateX(-50%); /* Center align for absolute positioning */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  z-index: 10; /* Ensure it's above other elements */
  opacity: 0; /* Initially hidden */
  transition:
    opacity 0.3s,
    transform 0.3s; /* Smooth transition for appearance */
  white-space: nowrap; /* Keep the message in one line */
}
.create-item-input:focus + .input-error-message,
.create-item-textarea:focus + .input-error-message {
  opacity: 1; /* Show message when input is focused */
  transform: translateX(-50%) translateY(-10px); /* Slight move upwards on focus */
}

.input-error-nudge {
  animation: nudge 0.5s ease-out;
  border: 2px solid red; /* Highlight border in red */
}

.confirm-attempted .input-error-nudge {
  border: 0.5px red; /* Highlight border in red */
}

.input-error-nudge {
  animation: nudge 0.5s ease-out;
}
.create-item-delete-button {
  background-color: #ff4d4d; /* Red background for delete button */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

/* Adjustments for hover effects */
.copy-button:hover,
.polyscan-button:hover {
  opacity: 0.8;
}

.image-download-container {
  position: relative;
  display: inline-block;
}

.generated-image {
  display: block; /* Ensures the image takes up the entire container width */
  max-width: 100%; /* Adjust the size as needed */
  height: auto;
}

.image-download-button {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  background-color: #007bff; /* Techy blue color */
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  opacity: 0.8;
}

.image-download-button:hover {
  opacity: 1;
}
.listing-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(135deg, #0077b6, #00b4d8);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  z-index: 1000;
  animation: scaleUp 0.3s ease-in-out;
}

@keyframes scaleUp {
  from {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
.nft-content {
  position: relative;
  /* Rest of your styles for nft-content */
}

.nft-image-container {
  position: relative;
}

.nft-listed-badge {
  position: absolute;
  top: 2px; /* Adjust as needed */
  right: 4px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #28a745; /* Green background */
  color: white;
  border-radius: 20px; /* Slightly rounded for the badge */
  padding: 5px 10px;
  font-size: 13px;
  padding: 3.5px 7px; /* 70% of the original padding */
  font-size: 11px; /* 70% of the original font size for "Listed!" text */
  border-radius: 14px; /* Adjusted for the smaller badge size */
}

.badge-icon {
  font-size: 20px; /* Larger tick mark */
}

.badge-text {
  display: block; /* Show the text "Listed!" */
  margin-left: 5px; /* Space between tick mark and text */
  font-size: 10px;
}

.dall-e-logo {
  height: 40px; /* Adjust the height as needed */
  width: auto; /* Keeps the aspect ratio */
  margin-left: 8px; /* Space between text and image */
  vertical-align: middle; /* Aligns image with the text */
}
/* MOBILE STYLEZZZ*/
/* stepzz*/
/* Mobile Styles */
@media (max-width: 768px) {
  .step {
    font-size: 0.9em; /* Reduce font size for mobile screens */
    padding: 6px; /* Reduce padding for mobile screens */
  }

  /* Adjust the width of the lines for mobile screens */
  .step::before {
    width: calc(50% - 12px);
  }

  /* Adjust the size of the checkmark icon for mobile screens */
  .step.completed::before {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 1em;
    top: -12px;
  }
}
/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .create-item-step {
    padding: 10px;
    margin-bottom: 10px;
  }

  .toggle-switch-container {
    flex-direction: column; /* Stack label and switch vertically */
    align-items: flex-start;
  }

  .toggle-switch {
    width: 50px; /* Slightly smaller switch for mobile */
    height: 28px;
  }

  .slider {
    border-radius: 28px; /* Adjusted to match switch height */
  }

  .slider:before {
    height: 22px; /* Smaller slider knob */
    width: 22px;
    left: 3px; /* Adjust position */
    bottom: 3px;
  }

  input:checked + .slider:before {
    transform: translateX(24px); /* Adjust movement distance */
  }

  .create-item-file-input,
  .create-item-button,
  .create-item-delete-button {
    width: 80%; /* Full width for easier interaction */
    padding: 10px; /* Larger touch targets */
  }

  .create-item-button {
    width: 90%; /* Reduce button width to 90% */
    margin-left: auto; /* Center the button */
    margin-right: auto;
  }

  .create-item-button,
  .create-item-delete-button {
    font-size: 0.9rem; /* Adjust font size for readability */
  }

  .create-item-delete-button {
    margin-top: 5px;
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for smaller phones */
  .toggle-switch-container {
    gap: 5px; /* Reduce gap */
  }

  .toggle-switch {
    width: 40px; /* Smaller switch for smaller phones */
    height: 22px;
  }

  .slider {
    border-radius: 22px;
  }

  .slider:before {
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
  }

  input:checked + .slider:before {
    transform: translateX(20px); /* Further adjust movement distance */
  }

  .toggle-switch-label {
    font-size: 0.8rem; /* Smaller text */
  }

  .create-item-button,
  .create-item-delete-button {
    padding: 8px; /* Adjust padding */
    font-size: 0.8rem; /* Smaller font size */
    width: 80%; /* Even smaller width for smaller phones */
  }
}

@media screen and (max-width: 320px) {
  /* Additional adjustments for very small screens */
  .create-item-button,
  .create-item-delete-button {
    padding: 6px;
    font-size: 0.7rem;
    width: 75%; /* Smallest width for very small screens */
  }

  /* Further adjustments for the toggle switch if necessary */
}
/* CONFIRM STEP Mobile Responsive Adjustments */
@media screen and (max-width: 768px) {
  .nft-details {
    padding: 15px;
    font-size: 0.9em;
  }

  .nft-detail {
    max-width: 90%; /* Increase width for mobile */
  }

  .nft-detail.name {
    font-size: 16px; /* Slightly smaller for mobile */
    margin: 8px 0;
  }

  .nft-detail.description {
    font-size: 12px;
  }

  .nft-detail.price {
    font-size: 0.9em;
    padding: 4px 8px;
  }
}

@media screen and (max-width: 480px) {
  .nft-details {
    padding: 10px;
  }

  .nft-detail.name {
    font-size: 15px; /* Further reduce for smaller screens */
  }

  .nft-detail.description {
    font-size: 11px;
  }

  .nft-detail.price {
    font-size: 0.85em;
    padding: 3px 6px;
  }
}

@media screen and (max-width: 320px) {
  /* Further refinements for very small screens */
  .nft-detail.name {
    font-size: 14px;
  }

  .nft-detail.description {
    font-size: 10px;
  }

  .nft-detail.price {
    font-size: 0.8em;
  }
}

/*Mint Success*/
/* Base Styles (Unchanged) */
/* Add your existing CSS here */

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  /* Success Message Styles for Mobile */
  .success-message {
    font-size: 16px; /* Slightly smaller font size for mobile */
  }

  .success-message-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start;
  }

  /* NFT Content Styles for Mobile */
  .nft-preview-image {
    max-width: 150px; /* Smaller image size for mobile */
  }

  .nft-details {
    font-size: 0.9em; /* Slightly smaller text for readability */
  }

  /* Blockchain Details Button Styles for Mobile */
  .Blockchain-details-button {
    padding: 6px 12px; /* Smaller padding */
    font-size: 0.8em; /* Smaller font size */
  }

  /* Modal Content Styles for Mobile */
  .modal-content {
    max-width: 90%; /* Adjust width for smaller screens */
    padding: 15px; /* Adjust padding */
  }

  /* PolyScan Button Styles for Mobile */
  .polyscan-button {
    font-size: 0.8em; /* Smaller font size */
    padding: 4px 8px; /* Adjust padding */
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for smaller phones */
  .success-message {
    font-size: 14px;
  }

  .nft-preview-image {
    max-width: 120px; /* Even smaller image size */
  }

  .nft-details {
    font-size: 0.8em; /* Further reduce font size */
  }

  .Blockchain-details-button {
    padding: 5px 10px;
    font-size: 0.75em;
  }

  .modal-content {
    max-width: 80%; /* More width adjustment */
    padding: 10px;
  }

  .polyscan-button {
    font-size: 0.75em;
    padding: 3px 6px;
  }
}

@media screen and (max-width: 320px) {
  /* Additional adjustments for very small screens */
  .success-message {
    font-size: 12px;
  }

  .nft-preview-image {
    max-width: 100px; /* Smallest image size */
  }

  .nft-details {
    font-size: 0.7em; /* Smallest font size */
  }

  .Blockchain-details-button {
    font-size: 0.7em;
  }

  .modal-content {
    max-width: 75%;
    padding: 8px;
  }

  .polyscan-button {
    font-size: 0.7em;
    padding: 2px 4px;
  }
}
